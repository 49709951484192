/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import type { Request } from '@tag/graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestsGQL } from '@shared/apollo/queries/request';
import { Paginated } from '@stores-models/paginated';
import { ApiService } from '@services/api.service';
import { BatchCreateEditRequest, RequestReportInspection } from '@api/types';
import { ReportInspection } from '@stores-actions/request.action';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class RequestStoreService {
  constructor(private requestsGQL: RequestsGQL, private api: ApiService) {}

  /**
   * Fetchs request
   *
   * @param filter OData filter
   *
   * @returns request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchRequests(filter?: string): Observable<Request[]> {
    return this.requestsGQL
      .watch({ filter })
      .valueChanges.pipe(map((result) => result.data.requests.items));
  }

  /**
   * Fetchs request
   *
   * @param filter OData filter
   *
   * @returns request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPaginatedRequests(
    top: number,
    skip: number,
    filter?: string,
    orderBy?: string,
    desc?: boolean
  ): Observable<Paginated<Request>> {
    return this.requestsGQL
      .watch({ filter, top, skip, orderBy, desc })
      .valueChanges.pipe(
        map((result) => ({
          items: result.data.requests.items,
          totalCount: result.data.requests.totalCount,
          skip,
          top,
          filter,
          orderBy,
          desc,
        }))
      );
  }

  /**
   * Deletes request
   *
   * @param no
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteRequest(no: string): Observable<any> {
    return this.api.delete(`/requests/${no}`);
  }

  /**
   * Deletes multiple requests
   *
   * @param nos
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteRequests(nos: string[]): Observable<any> {
    throw new Error('Method not implemented.');
  }

  /**
   * Adds request
   *
   * @param payload
   *
   * @returns request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addRequest(payload: Request): Observable<Request> {
    throw new Error('Method not implemented.');
  }

  /**
   * Adds request
   *
   * @param payload
   *
   * @returns request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addRequests(payload: BatchCreateEditRequest): Observable<any> {
    return this.api.post('/requests', payload);
  }

  /**
   * Updates request
   *
   * @param no
   * @param patch
   *
   * @returns request
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateRequest(no: string, patch: any[]): Observable<Request> {
    throw new Error('Method not implemented.');
  }

  // /**
  //  * Create Update request
  //  *
  //  * @param no
  //  *
  //  * @returns request
  //  * @deprecated This is an internal implementation method, do not use directly.
  //  */
  // createUpdate(no: string, patch: Operation[], ): Observable<Request> {
  //   return this.requestsService.RequestsNoReportPost(company, no, patch);
  // }

  /**
   * Updates list of requests
   *
   * @param nos
   * @param patch
   *
   * @returns requests
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateRequests(nos: string[], patch: any[]): Observable<Request[]> {
    throw new Error('Method not implemented.');
  }

  /**
   * Report Inspection
   *
   * @param payload Request Report Inspection payload
   *
   * @returns requests
   * @deprecated This is an internal implementation method, do not use directly.
   */
  reportInspection(payload: RequestReportInspection): Observable<any> {
    return this.api.post(
      `/requests/${payload.request_No}/report-inspection`,
      payload
    );
  }
}
