import type { Request } from '@tag/graphql';
import {
  BatchCreateEditRequest,
  RequestReportInspection,
  WorkOrderFromRequestPost,
} from '@api/types';
import { Operation } from 'fast-json-patch';

/**
 * Upsert Requests
 *
 * @param payload Request object
 */
export class UpsertRequest {
  static readonly type = '[Request] Upsert';

  constructor(public payload: BatchCreateEditRequest) {}
}

/**
 * Get all Requests
 *
 * @param filter OData filter
 * @param bustCache Bypass current cached stated to get new items from the API.
 */
export class GetRequests {
  static readonly type = '[Request] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get summary Requests
 *
 * @param filter OData filter
 */
export class GetSummaryRequests {
  static readonly type = '[Request] Get Summary';

  constructor(public filter?: string) {}
}

/**
 * Get summary Requests
 *
 * @param filter OData filter
 */
export class GetPaginatedRequests {
  static readonly type = '[Request] Get Paginated';

  constructor(
    public top: number,
    public skip: number,
    public filter?: string,
    public orderBy?: string,
    public desc?: boolean
  ) {}
}

/**
 * Update Request, this method will dynamically update the cache Request list and selected Request.
 *
 * @param payload Report inspection payload object
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class ReportInspection {
  static readonly type = '[Requirement] Report Inspection';

  constructor(
    public payload: RequestReportInspection,
    public selectedItem?: Request | null
  ) {}
}

/**
 * Update Request, this method will dynamically update the cache request list and selected request.
 *
 * @param no Request No
 * @param patch JSON patch object used to update the Request. Import Operation[] from fast-json-patch
 * @param selectedItem Force the selected item to change after the update. By default, it will take the updated object.
 */
export class UpdateRequests {
  static readonly type = '[Request] Update multiple';

  constructor(
    public nos: string[],
    public patch: Operation[],
    public selectedItem?: Request | null
  ) {}
}

/**
 * Delete Request, this method will dynamically update the cache request list and set the selected request to null.
 *
 * @param no Request No
 */
export class DeleteRequest {
  static readonly type = '[Request] Delete';

  constructor(public no: string) {}
}

/**
 * Delete Requests, this method will dynamically update the cache request list and set the selected request to null.
 *
 * @param no Request Nos
 */
export class DeleteRequests {
  static readonly type = '[Request] Delete Multiple';

  constructor(public nos: string[]) {}
}

/**
 * Set selected Request
 *
 * @param payload New Request full object.
 */
export class SetSelectedRequest {
  static readonly type = '[Request] Set';

  constructor(public payload: Request | null) {}
}

/**
 * Set multiple selected Request
 *
 * @param payload List of new Request full object.
 */
export class SetSelectedRequests {
  static readonly type = '[Request] Set Multiple';

  constructor(public payload: Request[]) {}
}

/**
 * Locally update a request when converted to WO. Do not use directly.
 *
 * @param payload Work Order From Request Post Object
 * @param payload Work Order No
 */
export class UpdateLocalRequestFromWo {
  static readonly type = '[Request From Wo] Delete Local Request from WO.';

  constructor(public payload: WorkOrderFromRequestPost, public no: string) {}
}
