import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import type { Request } from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AddI18nNotification } from '@stores-actions/notification.action';
import {
  UpsertRequest,
  DeleteRequest,
  DeleteRequests,
  GetPaginatedRequests,
  GetRequests,
  ReportInspection,
  SetSelectedRequest,
  SetSelectedRequests,
  UpdateRequests,
} from '@stores-actions/request.action';
import { Paginated } from '@stores-models/paginated';
import { RequestStoreService } from '@stores-services/request-store.service';
import { RequirementStoreService } from '@stores-services/requirement-store.service';
import { AuthState } from '@stores-states/authentication.state';
import { BatchCreateEditRequest, RequestReportInspection } from '@api/types';

export class RequestStateModel {
  requests: Request[] = [];
  paginatedRequests: Paginated<Request>[] = [];
  activePaginatedRequests!: Paginated<Request> | null;
  selectedRequest!: Request | null;
  selectedRequests: Request[] = [];
  initialized = false;
}

/**
 * Requests metadata and action mappings.
 */
@State<RequestStateModel>({
  name: 'request',
  defaults: {
    requests: [],
    paginatedRequests: [],
    activePaginatedRequests: null,
    selectedRequest: null,
    selectedRequests: [],
    initialized: false,
  },
})
@Injectable()
export class RequestState {
  constructor(
    private requestStoreService: RequestStoreService,
    private store: Store,
    private requirementStoreService: RequirementStoreService,
    private apollo: Apollo
  ) {}

  static getRequestsByArea(
    no: string
  ): (state: RequestStateModel) => Request[] {
    return createSelector([RequestState], (state: RequestStateModel) =>
      state.requests.filter((req) => req.area === no)
    );
  }

  static getRequestsByFacility(
    no: string
  ): (state: RequestStateModel) => Request[] {
    return createSelector([RequestState], (state: RequestStateModel) =>
      state.requests.filter((req) => req.facility === no)
    );
  }

  static getRequestsByPagination(
    top: number,
    skip: number,
    filter?: string
  ): (state: RequestStateModel) => Paginated<Request> | undefined {
    return createSelector([RequestState], (state: RequestStateModel) => {
      return state.paginatedRequests.find(
        (req) => req.top === top && req.skip === skip && req.filter === filter
      );
    });
  }

  @Selector()
  static getRequests(state: RequestStateModel): Request[] {
    return state.requests;
  }

  @Selector()
  static getActiveRequestPage(
    state: RequestStateModel
  ): Paginated<Request> | null {
    return state.activePaginatedRequests;
  }

  @Selector()
  static getReviewRequests(state: RequestStateModel): Request[] {
    return state.requests.filter((s) => s.workOrderType?.trim() === '');
  }

  @Selector()
  static getAvailableRequests(state: RequestStateModel): Request[] {
    return state.requests.filter((s) => s.reviewCondition !== 'Denied');
  }

  @Selector()
  static getSelectedRequest(state: RequestStateModel): Request | null {
    return state.selectedRequest;
  }

  @Selector()
  static getSelectedRequests(state: RequestStateModel): Request[] {
    return state.selectedRequests;
  }

  @Selector()
  static getInitStatus(state: RequestStateModel): boolean {
    return state.initialized;
  }

  @Action(GetRequests, { cancelUncompleted: true })
  getRequests(
    { getState, setState }: StateContext<RequestStateModel>,
    { filter, bustCache }: GetRequests
  ): Observable<Request[]> {
    let state = getState();
    if (state.initialized && !bustCache) return of(state.requests);
    return this.requestStoreService.fetchRequests(filter).pipe(
      tap((result) => {
        state = getState();
        setState({
          ...state,
          requests: result,
          initialized: true,
        });
      })
    );
  }

  @Action(GetPaginatedRequests, { cancelUncompleted: true })
  getPaginatedRequests(
    { getState, setState }: StateContext<RequestStateModel>,
    { filter, top, skip, orderBy, desc }: GetPaginatedRequests
  ): Observable<Paginated<Request>> {
    let state = getState();
    setState({
      ...state,
      activePaginatedRequests: null,
    });

    return this.requestStoreService
      .fetchPaginatedRequests(top, skip, filter, orderBy, desc)
      .pipe(
        tap((result) => {
          state = getState();
          const paginatedRequests = [...state.paginatedRequests];

          const index = paginatedRequests.findIndex(
            (req) =>
              req.top === top && req.skip === skip && req.filter === filter
          );
          if (index === -1) paginatedRequests.push(result);
          else paginatedRequests[index] = result;

          setState({
            ...state,
            paginatedRequests,
            activePaginatedRequests: result,
          });
        })
      );
  }

  @Action(UpsertRequest)
  addRequests(
    { patchState }: StateContext<RequestStateModel>,
    { payload }: UpsertRequest
  ): Observable<BatchCreateEditRequest> {
    return this.requestStoreService.addRequests(payload).pipe(
      tap((result) => {
        const request: Request = {
          ...result,
          workOrderNo: result.woNo,
        } as Request;

        patchState({
          selectedRequest: request,
        });

        const message = {
          key: payload.convert_To_WorkOrder
            ? 'notificationsMessagesKeys.requestNoWasConvertedSuccessfullykeyParam'
            : payload.no
            ? 'notificationsMessagesKeys.requestNoWasUpdatedSuccessfullyKeyParam'
            : 'notificationsMessagesKeys.requestNoWasCreatedSuccessfullyKeyParam',
          params: {
            id: request.no,
            idWorkOrder: result.woNo,
          },
        };
        this.store.dispatch(
          new AddI18nNotification(
            message,
            'success',
            payload.no ? 'updatedKey' : 'createKey'
          )
        );
        if (payload.convert_To_WorkOrder && result.woNo)
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'workOrders',
          });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requests',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'comments',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requirements',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'feedbacks',
        });
      })
    );
  }

  @Action(ReportInspection)
  reportInspection(
    { patchState, getState }: StateContext<RequestStateModel>,
    { payload, selectedItem }: ReportInspection
  ): Observable<RequestReportInspection> {
    return this.requestStoreService.reportInspection(payload).pipe(
      tap((result) => {
        const state = getState();
        const requestList = [...state.requests];
        const requestIndex = requestList.findIndex(
          (item) => item.no === result.request_No
        );
        requestList[requestIndex] = { ...requestList[requestIndex], ...result };

        patchState({
          selectedRequest:
            selectedItem !== undefined
              ? selectedItem
              : requestList[requestIndex],
        });

        const message = {
          key: 'notificationsMessagesKeys.requestNoWasUpdatedSuccessfullyKeyParam',
          params: {
            id: result.request_No,
          },
        };
        this.store.dispatch(
          new AddI18nNotification(message, 'success', 'updatedKey')
        );

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requests',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requirements',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'comments',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'feedbacks',
        });
      })
    );
  }

  @Action(UpdateRequests)
  updateRequests(
    { getState, setState }: StateContext<RequestStateModel>,
    { patch, nos }: UpdateRequests
  ): Observable<Request[]> {
    return this.requestStoreService.updateRequests(nos, patch).pipe(
      tap((results) => {
        const state = getState();
        const requestList = [...state.requests];
        const updatedRequestIds: string[] = [];
        nos.forEach((requestKey) => {
          const requestIndex = requestList.findIndex(
            (item) => item.no === requestKey
          );
          const request = results.find((item) => item.no === requestKey);
          if (request?.no) {
            requestList[requestIndex] = request;
            updatedRequestIds.push(request.no);
          }
        });
        setState({
          ...state,
          requests: requestList,
          selectedRequests: results,
        });

        const message = {
          key: 'notificationsMessagesKeys.requestNoWasUpdatedSuccessfullyKeyParam',
          params: {
            id: updatedRequestIds.join(','),
          },
        };
        this.store.dispatch(
          new AddI18nNotification(message, 'success', 'updatedKey')
        );
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requests',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'comments',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requirements',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'feedbacks',
        });
      })
    );
  }

  @Action(DeleteRequest)
  deleteRequest(
    { patchState }: StateContext<RequestStateModel>,
    { no }: DeleteRequest
  ): Observable<Request> {
    return this.requestStoreService.deleteRequest(no).pipe(
      tap(() => {
        patchState({
          selectedRequest: null,
        });

        const message = {
          key: 'notificationsMessagesKeys.requestNoWasDeletedSuccessfullyKeyParam',
          params: { id: no },
        };
        this.store.dispatch(
          new AddI18nNotification(message, 'success', 'deletedKey')
        );
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requests',
        });
      })
    );
  }

  @Action(DeleteRequests)
  deleteRequests(
    { getState, setState }: StateContext<RequestStateModel>,
    { nos }: DeleteRequests
  ): Observable<Request[]> {
    return this.requestStoreService.deleteRequests(nos).pipe(
      tap(() => {
        const state = getState();
        const deletedRequestIds: string[] = [];
        nos.forEach((requestKey) => {
          if (requestKey) {
            deletedRequestIds.push(requestKey);
          }
        });

        setState({
          ...state,
          selectedRequests: [],
        });

        const message = {
          key: 'notificationsMessagesKeys.requestNoWasDeletedSuccessfullyKeyParam',
          params: {
            id: deletedRequestIds.join(','),
          },
        };
        this.store.dispatch(
          new AddI18nNotification(message, 'success', 'deletedKey')
        );
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requests',
        });
      })
    );
  }

  @Action(SetSelectedRequest)
  setSelectedRequest(
    { getState, setState }: StateContext<RequestStateModel>,
    { payload }: SetSelectedRequest
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedRequest: payload,
    });
  }

  @Action(SetSelectedRequests)
  setSelectedRequests(
    { getState, setState }: StateContext<RequestStateModel>,
    { payload }: SetSelectedRequests
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedRequests: payload,
    });
  }
}
